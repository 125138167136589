import { useRouter, createRouter, createWebHistory } from "vue-router";





const routes = [{
        path: "/short",
        name: "short",
        component: () =>
            import ("@/views/short/index.vue"),
    },
    {
        path: "/tag",
        name: "tag",
        component: () =>
            import ("@/views/tag/index.vue"),
    },
    {
        path: "/shortPC",
        name: "shortPC",
        component: () =>
            import ("@/views/shortPC/index.vue"),
    },
    {
        path: "/tagPC",
        name: "tagPC",
        component: () =>
            import ("@/views/tagPC/index.vue"),
    },

    {
        path: "/",
        name: "home",
        component: () =>
            import ("@/views/home/index.vue"),
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from) => {


})
router.afterEach((to, from) => {

})

export default router