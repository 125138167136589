import { showToast } from 'vant';
import useClipboard from 'vue-clipboard3'
import { useRouter } from "vue-router";




const common = {

    currentTime: (time = 0) => {

        let date = new Date(time * 1000); // 参数需要毫秒数，所以这里将秒数乘于 1000

        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        let h = (date.getHours() < 10 ? '0' + (date.getHours() + 1) : (date.getHours() + 1)) + ':';
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

        return Y + M + D + h + m + s;
    },

    copy: (text) => {
        if (!text) {
            showToast('text is empty!');
            return
        }
        const { toClipboard } = useClipboard()
        try {
            toClipboard(text)
            showToast('success')
        } catch (e) {
            showToast('failed')
        }
    },

    isEmpty(data) {

        if (
            data === 0 ||
            data === '0' ||
            data === 0.0 ||
            data === '0.0' ||
            data === undefined ||
            data === "undefined" ||
            data === null ||
            data === '' ||
            data === false ||
            JSON.stringify(data) == '{}' ||
            JSON.stringify(data) == '[]'
        ) {
            return true;
        } else {
            return false;
        }
    },


    verifyParams(params) {

        if (common.isEmpty(params)) {
            showToast('Please input frist!');
            throw SyntaxError();
        }

        for (let index in params) {
            if (common.isEmpty(params[index])) {
                showToast(index + ' is empty!');
                throw SyntaxError();
            }
        }
    },


    isMobile: () => {
        let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        return flag;
    },



    isAndroid: () => {
        let u = navigator.userAgent;
        return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
    },

    tranSec: (s) => {
        //算法：将秒数除以60，然后下舍入，既得到分钟数
        var h;
        h = Math.floor(s / 60);
        //计算秒
        //算法：取得秒%60的余数，既得到秒数
        s = s % 60;
        //将变量转换为字符串
        h += '';
        s += '';
        //如果只有一位数，前面增加一个0
        h = (h.length == 1) ? '0' + h : h;
        s = (s.length == 1) ? '0' + s : s;
        return h + ':' + s;
    },

    setInfo: (key, data) => {
        data = JSON.stringify(data)
        sessionStorage.setItem(key, data)
    },

    getInfo: (key) => {
        return JSON.parse(sessionStorage.getItem(key))
    },
    numFormat: (num) => {
        if (num >= 1000) {
            num = Math.round(num / 100) / 10 + "k"
        }
        return num
    }

    // addParameterToUrl: (parameterName, parameterValue) => {
    //     let router = useRouter()
    //         // 创建一个新的query对象，包含你想添加的参数
    //     const newQuery = {
    //         ...router.currentRoute.value.query,
    //         [parameterName]: parameterValue
    //     };

    //     // 使用push方法更新URL，但不会导致新页面的加载
    //     router.push({
    //         path: router.currentRoute.value.path,
    //         query: newQuery
    //     })
    // }



}

export default common;